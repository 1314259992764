import React from "react"
import { graphql } from "gatsby"

import Layout from "../layout/layout"
import SEO from "../layout/seo"

import Products from "../sections/Products"

export default function CategoryPage(props) {
  const category = props.data.contentfulCategory
  const { name, description, product } = category

  return (
    <Layout>
      <SEO title={name} />
      <Products
        title={name}
        description={description && description.description}
        products={product || []}
      />
    </Layout>
  )
}

export const query = graphql`
  query ($id: String!) {
    contentfulCategory(id: { eq: $id }) {
      id
      name
      description {
        description
      }
      product {
        id
        name
        description {
          description
        }
        price
        category {
          name
          slug
        }
        subcategory {
          name
          slug
        }
        mainImage {
          gatsbyImageData(
            formats: NO_CHANGE
            placeholder: BLURRED
            layout: CONSTRAINED
            aspectRatio: 1
          )
        }
        images {
          gatsbyImageData(
            placeholder: BLURRED
            formats: NO_CHANGE
            layout: FULL_WIDTH
          )
        }
        slug
      }
    }
  }
`
